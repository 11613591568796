/* answers component */
.answers {
  display: grid;
  grid-template-rows: repeat(5, auto);
  gap: 10px;
  margin: 2rem 0;
  grid-auto-flow: column;
}

@media only screen and (max-width: 1024px) {
  .answers {
    grid-auto-flow: unset;
    margin: 1rem 0;
  }
}

.answer {
  width: 100%;
  height: 58px;
  background: var(--questionBG);
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 1.1rem;
  cursor: pointer;
  border-radius: 4px;
}

.answer:hover {
  background: hsla(229, 20%, 57%, 0.253);
}

.answer input {
  margin-right: 15px;
}

.answer span {
  width: 100%;
}

.answer span:nth-of-type(2) {
  text-align: right;
  color: #4e536f;
  font-size: 14px;
}

.correct {
  background: var(--successGreen) !important;
  font-weight: 600;
}

.wrong {
  background: var(--dangerRed) !important;
  font-weight: 600;
}
