/* question component */
.question {
  background-color: white;
  padding: 10px;
  margin: 1rem 0;
  border-radius: 4px;
}

.qtitle {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--fontPrimary);
  font-weight: 600;
}

.qtitle span {
  color: var(--active);
}
