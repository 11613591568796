/* illustration component */
.illustration {
  padding: 5rem 2rem;
}

.illustration img {
  max-width: 80%;
}

@media only screen and (max-width: 1023px) {
  .illustration {
    display: none;
  }
}
