/* summary comoponent */

.summary {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .summary {
    flex-direction: column;
    align-items: center;
  }
}

.point {
  width: 50%;
  display: grid;
  justify-content: center;
  place-items: center;
  position: relative;
}

.score {
  width: 400px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.badge {
  text-align: center;
  height: 300px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 800px) {
  .badge {
    width: 100%;
  }
}
